.not-found {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.not-found img {
    max-width:300px;
}

.not-found .content {
    margin: 30px 0;
}