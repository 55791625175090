.ant-popover-inner-content {
    max-width: 350px;
}

.ant-collapse-header {
    display: flex;
    align-items: center;
}

.ant-collapse-header .ant-typography {
    display: inline;
    margin: 0;
}

.ant-collapse-extra {
    margin-left: auto;
}