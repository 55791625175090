.slide-item {
    width: 115px;
    text-shadow: 0px 0px 2px #000;
    display: inline-flex;
}

.slide-item > ul{
    display:grid;
    border-top:none;
}

.slide-item > ul > li:not(:last-child){
    border-right: none;
}

.slide-item .ant-card-body {
    padding: 1px 10px;
    background: rgba(0, 0, 0, 0);
}

.slide-item.ant-card-bordered {
    border:none;
    background: rgba(0, 0, 0, 0);
}

/* .slide-item:hover:not(.selected) {
    border-color: #666!important;
}

.slide-item.selected, .slide-item.selected:hover {
    border-color: #dadada;
}

.slide-item.selected.has-errors {
    border-color: #a61d24;
}

.slide-item.has-errors .slide-title {
    color: #a61d24;
}

.slide-item .twitter-picker {
    position: absolute!important;
    right: calc(-100% + 80px);
    top: 55px;
    z-index:1000;
}

.color-picker .ant-popover-arrow {
    display: none;
}

.color-picker .ant-popover-inner {
    background: transparent;
    box-shadow: none;
    transform: translate(-25px, -10px)
}

.slide-item .color-picker-icon {
    opacity: 0;
    transform: scale(.5);
    transition: opacity .5s, transform .5s;
}

.slide-item.selected .color-picker-icon {
    opacity: 1;
    transform: scale(1);
}

.slide-title input {
    max-width: 200px;
    background: white;
    color: #444;
} */

.slide-title input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    /* color: #999; */
}

/* bug fix for flickering when reordering slide */
.slide-item, .droplet, .slide-item .color-picker-icon {
    /* transition: none!important; */
}