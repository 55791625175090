.experience-editor {
    min-height: calc(100vh - 200px);
}

.name-form {
    display: inline-block;
    margin: 0 24px 24px 0;
    max-width:400px;
}

.name-form input {
    font-size: 28px;
}

#links {
    background-color: #222;
    height:400px;
    display:grid;
    margin-top: 100px;
    font-size: 28px;
    border: 1px solid #434343;
    border-radius: 2px;
}

#links span{
    margin: 33% 20px;

}

#links span:hover{
    color: #177ddc;
}

#qr-code:hover {
    z-index: 10;
}