.slide {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.slide > div {
    margin-right: 10px;
}

button.droplet-option {
    padding: 0;
    height: auto;
}