.ant-upload.ant-upload-select-picture-card.ant-upload-disabled {
    border-style: solid;
}

.ant-upload.ant-upload-select-picture-card.ant-upload-disabled:hover {
    border-color: #434343;
}

.ant-upload.ant-upload-drag .ant-upload {
    padding: 0!important;
}

.avatar-uploader > .ant-upload {
    width: 100%!important;
    height: 128px!important;
}

.uploader-large-icon {
    font-size: 36px;
}

.remove-file {
    margin-top: 8px;
}

.ant-upload .image-preview {
    max-width: 300px;
    max-height: 300px;
}
