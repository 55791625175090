/* @import "~antd/dist/antd.css"; */
@import '~antd/dist/antd.dark.css';

main {
    zoom: 0.8; 
    -moz-transform: scale(0.8); 
    -moz-transform-origin: 0 0;
}

/* @media (min-width:1000px) {
    main {
        zoom: 1; 
        -moz-transform: scale(1); 
        -moz-transform-origin: 0 0;
    }
} */

@media (min-height:800px) and (min-width:1000px) {
    #experience-preview {
        transform:scale(1)
    }
    main {
        zoom: 1; 
        -moz-transform: scale(1); 
        -moz-transform-origin: 0 0;
    }
}


.ant-layout {
    background: #111;
} 

.logo {
    height: 31px;
    margin: 16px 28px 16px 0;
    float: left;
}

/* .site-layout-background, .site-layout-background .ant-layout {
    background: #fff;
} */

.dashboard-sidebar {
    padding: 24px;
    background: #191919;
    border: 1px solid #333;
}

.slide-list-item {
    transform: translateX(5px);
    cursor: pointer;
}

/* Log In Form */
#log-in {
    margin-top: 100px;
}
#log-in .login-form-forgot {
    float: right;
}
#log-in .ant-col-rtl .login-form-forgot {
    float: left;
}
#log-in .login-form-button {
    width: 100%;
}

.present-button {
    margin-left: 10px;
}